/* : to use for the top fixed nav => https://gist.github.com/nicoxxxcox/87b504a98efb6b0ca791b7a6d56319cd */
.affix {
  top: 0;
  right: 0;
  left: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.btn-wrap .btn {
  margin-bottom: .6rem;
  margin-right: .6rem;
}

.list-plan-site {

  >li {
    padding-top: .8rem;

    >a {
      font-weight: 700;
    }

    &::before {
      content: '-';
      margin-right: .5rem;
    }
  }
}

.list-plan-site--niv-2 {
  font-size: $font-size-small;

  >li {
    padding-top: .2rem;
    
    >a {
      font-weight: 400;
    }

    &::before {
      content: '-';
      margin-right: .5rem;
      padding-left: 1rem;
    }
  }
}

//Lydia//

.divider-img {
  background: url(.../images/charte/divider.png) center no-repeat;
  height: 20px;
  max-width: 400px;
  margin: 10px auto;
}

