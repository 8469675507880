/* ==========================================================================
  Fiche rsum
  ========================================================================== */

.fiche-resum {
  @extend .center-block;
  position: relative;
  overflow: hidden;
  max-width: 27rem;
  margin-bottom: 0;
}

.fiche-resum_label {
  position: absolute;
  z-index: 1;
  top: .5rem;
  right: .5rem;
}

.fiche-resum_img-crop {
  display: block;
  height: 20rem;
  width: 100%;
  object-fit: cover;

}
.fiche-resum_img-crop2 {
  display: block;
  height: 5rem;
  width: 100%;
  object-fit: scale-down;
}

.fiche-resum_bottom {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($brand-second, .7);
  opacity: 0;
  transition: ease .6s;

  .btn-link {
    color: white;
    font-weight: 600;
    border: solid 1px white;
    border-radius: 3rem;
    position: relative;
    top: 2rem;
    transition: top ease .3s, opacity ease .3s;
  }
}

.fiche-resum:hover {

  .fiche-resum_bottom  {
    opacity: 1;
  }

  .btn-link {
    top: 0;
    opacity: 1;
  }
}

.favoris--resum {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
}