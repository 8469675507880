.logos-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.logos-grid__item {
	padding: .5rem;

	img {
		display: block;
		width: 100%;
		height: 10rem;
		aspect-ratio: 1 / 1;
		object-fit: contain;
		//padding: 1rem;
		border: solid 1px #ddd;
	}
}

.logos-grid__caption {
	display: block;
	padding: .5rem;
}

.logos-grid__img {
	width: 10rem;
	height: 10rem;
	object-fit: scale-down;
	border-radius: $border-radius-base;
}
